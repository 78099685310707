import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'

const Breadcrumb = ({ items }) => {
  return (
    <Inview className='breadcrumb'>
      <div className='bg' />
      <div className='inner fade-in'>
        <h4>
          {items.map((item, index) => {
            return <Item key={index} index={index} params={item} />
          })}
        </h4>
      </div>
    </Inview>
  )
}

const Item = (props) => {
  const { text, link } = props.params
  const { index } = props
  if (index === 0) {
    return (
      <span>
        <LinkWrap to={link}>{text}</LinkWrap>
      </span>
    )
  } else if (link) {
    return (
      <span>
        <span className='arrow-right'></span>
        <LinkWrap to={link}>{text}</LinkWrap>
      </span>
    )
  } else {
    // is active
    return (
      <span>
        <span className='arrow-right'></span>
        <span className='active' dangerouslySetInnerHTML={{ __html: text }} />
      </span>
    )
  }
}

export default Breadcrumb
