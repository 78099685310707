import React from 'react'
import Inview from 'components/Inview'
import ImagePng from 'components/ImagePng'
import { useLocalization } from 'gatsby-theme-i18n'

const Component = ({ hasBg }) => {
  const { locale } = useLocalization()

  let content = {
    en: {
      title: 'Our Partners',
      partners: [
        {
          logo: 'partner-sino-british-academy.png',
          title: 'Sino-British Academy',
          link: 'https://www.sinobritishacademy.com',
        },
        {
          logo: 'partner-international-feldenkrais-federation.png',
          title: "Int'l Feldenkrais Federation (IFF)",
          link: 'https://feldenkrais-method.org',
        },
        // {
        //   logo: 'partner-instituto-feldenkrais.png',
        //   title: 'Instituto Feldenkrais',
        //   link: 'https://www.feldenkrais-institute.com',
        // },
      ],
    },
    zh: {
      title: '合作伙伴',
      partners: [
        {
          logo: 'partner-sino-british-academy.png',
          title: '中瑛学院',
          link: 'https://www.sinobritishacademy.com',
        },
        {
          logo: 'partner-international-feldenkrais-federation.png',
          title: "Int'l Feldenkrais Federation (IFF)",
          link: 'https://feldenkrais-method.org',
        },
        // {
        //   logo: 'partner-instituto-feldenkrais.png',
        //   title: '菲等奎司协会',
        //   link: 'https://www.feldenkrais-institute.com',
        // },
      ],
    },
  }
  content = content[locale]

  return (
    <div id='footer-upper' className={hasBg ? 'has-bg' : ''}>
      <div className='border'>
        <div className='inner'></div>
      </div>
      <Inview className='inner fade-in'>
        <h3>{content.title}</h3>
        <div className='partners'>
          {content.partners.map((item, i) => (
            <Partner key={i} content={item} />
          ))}
        </div>
      </Inview>
    </div>
  )
}

export default Component

const Partner = ({ content }) => {
  return (
    <a className='item' href={content.link} target='_blank'>
      <div className='image'>
        <ImagePng filename={content.logo} alt='logo' />
      </div>
      <div className='text'>
        <p className='title'>{content.title}</p>
        <p className='link'>{content.link}</p>
      </div>
    </a>
  )
}
