import React from 'react'
import Swoosh from 'components/Swoosh'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  const { title, body, isLeftAligned } = content
  return (
    <Inview
      className={`component-center-block 
      ${isLeftAligned ? 'left-aligned' : ''}
      `}
    >
      <div className='inner fade-in'>
        <div className='text'>
          <h4>{title}</h4>
          <div className='body'>{renderRichText(body)}</div>
        </div>
      </div>
      <div className='swoosh-wrap'>
        <Swoosh left='-10%' width='140%' opacity='0.04' top='20%' />
      </div>
    </Inview>
  )
}

export default Component
