import React from 'react'
import ImagePng from 'components/ImagePng'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Component = ({ content }) => {
  const { icon, label, value, valueRichText } = content

  return (
    <div className='component-contact-item'>
      <div className='inner'>
        <div className='icon'>
          <ImagePng filename={icon} alt='icon' />
        </div>
        <h4>{label}</h4>
        {value && (
          <p>
            <span dangerouslySetInnerHTML={{ __html: value }} />
          </p>
        )}
        {valueRichText && (
          <div className='rich-text'>{renderRichText(valueRichText)}</div>
        )}
      </div>
    </div>
  )
}

export default Component
