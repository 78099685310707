import React from 'react'
import ContactItem from 'components/ContactItem'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

const Component = ({ content }) => {
  const { locale } = useLocalization()

  let tr = {
    en: {
      phone: 'Phone',
      email: 'Email',
    },
    zh: {
      phone: '电话',
      email: 'Email',
    },
  }
  tr = tr[locale]

  const contactItems = {
    phone: {
      icon: 'phone.png',
      label: tr.phone,
      value: content.phone,
    },
    email: {
      icon: 'email.png',
      label: tr.email,
      value: `<a href="mailto:${content.email}">${content.email}</a>`,
    },
  }

  return (
    <Inview>
      <div className='component-contact-upper'>
        <div className='inner fade-in'>
          <ContactItem content={contactItems.phone} />
          <ContactItem content={contactItems.email} />
        </div>
      </div>
    </Inview>
  )
}

export default Component
