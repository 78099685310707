import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Swoosh from 'components/Swoosh'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  const { image, caption, title, body } = content
  return (
    <Inview className='component-intro-block'>
      <div className='inner fade-in'>
        <div className='text'>
          {title && <h4>{title}</h4>}
          <div className={`body ${title ? '' : 'no-title'}`}>
            {renderRichText(body)}
          </div>
        </div>
        <div className='image'>
          <ImageWrap image={image} />
          {caption && <h4 className='caption'>{caption}</h4>}
          <div className='swoosh-wrap'>
            <Swoosh left='0' width='200%' opacity='0.1' />
          </div>
        </div>
      </div>
    </Inview>
  )
}

export default Component
