import React from 'react'
import IntroBlockExtended from 'components/IntroBlockExtended'
import IntroBlock from 'components/IntroBlock'
import CenterBlock from 'components/CenterBlock'
import ImageTextBlock from 'components/ImageTextBlock'
import ContactBlock from 'components/ContactBlock'

const Component = ({ body }) => {
  return (
    <div>
      {body.map((block, i) => {
        if (block.__typename === 'ContentfulBlockIntro') {
          return <IntroBlock key={i} content={block} />
        }
        if (block.__typename === 'ContentfulBlockIntroExtended') {
          return <IntroBlockExtended key={i} content={block} />
        }
        if (block.__typename === 'ContentfulBlockCenterText') {
          return <CenterBlock key={i} content={block} />
        }
        if (block.__typename === 'ContentfulBlockImageText') {
          return <ImageTextBlock key={i} content={block} />
        }
        if (block.__typename === 'ContentfulBlockContact') {
          return <ContactBlock key={i} content={block} />
        }
      })}
    </div>
  )
}

export default Component
