import React from 'react'
import ImageWrap from 'components/ImageWrap'
import ContactItem from 'components/ContactItem'
import Swoosh from 'components/Swoosh'
import ArrowLink from 'components/ArrowLink'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

const Component = ({ content }) => {
  const { locale } = useLocalization()

  // contactLower: {
  //   map: 'map-blue.jpg',
  //   address: {
  //     icon: 'map.png',
  //     text: {
  //       title: 'Address',
  //       body: `<p>2nd Floor,<br />
  //       Building 28, Lane 95, Wuyi Road<br />
  //       Changning 200050</p>`,
  //     },
  //   },
  //   links: {
  //     google: {
  //       text: 'Google Map',
  //       to: 'https://goo.gl/maps/gvfH58zK2DoW1Zry9',
  //       isExternal: true,
  //     },
  //     baidu: {
  //       text: 'Baidu Map',
  //       to: 'https://j.map.baidu.com/67/lJE',
  //       isExternal: true,
  //     },
  //   },
  // },
  // contactLower: {
  //   map: 'map-blue.jpg',
  //   address: {
  //     icon: 'map.png',
  //     text: {
  //       title: '地址',
  //       body: `<p>上海市长宁区武夷路95弄28号2楼<br />
  //       邮编 200050</p>`,
  //     },
  //   },
  //   links: {
  //     google: {
  //       text: '谷歌地图',
  //       to: 'https://goo.gl/maps/gvfH58zK2DoW1Zry9',
  //       isExternal: true,
  //     },
  //     baidu: {
  //       text: '百度地图',
  //       to: 'https://j.map.baidu.com/67/lJE',
  //       isExternal: true,
  //     },
  //   },
  // },

  let tr = {
    en: {
      address: 'Address',
      googleMap: 'Google Map',
      baiduMap: 'Baidu Map',
    },
    zh: {
      address: '地址',
      googleMap: '谷歌地图',
      baiduMap: '百度地图',
    },
  }
  tr = tr[locale]

  const contactItems = {
    address: {
      icon: 'map.png',
      label: tr.address,
      valueRichText: content.address,
    },
  }

  const mapLinks = {
    google: {
      to: content.googleMapLink,
      text: tr.googleMap,
    },
    baidu: {
      to: content.baiduMapLink,
      text: tr.baiduMap,
    },
  }

  return (
    <Inview className='component-contact-lower'>
      <div className='inner fade-in'>
        <div className='image'>
          <ImageWrap image={content.image} />
        </div>
        <div className='text'>
          <ContactItem content={contactItems.address} />
          <div className='links'>
            <ArrowLink content={mapLinks.google} />
            <ArrowLink content={mapLinks.baidu} />
          </div>
        </div>
      </div>
      <div className='swoosh-wrap'>
        <Swoosh left='-10%' width='140%' opacity='0.04' />
      </div>
    </Inview>
  )
}

export default Component
