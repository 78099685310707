import React from 'react'
import ImageWrap from 'components/ImageWrap'
import ButtonLink from 'components/ButtonLink'
import Swoosh from 'components/Swoosh'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  return (
    <div className='component-intro-block-extended'>
      <div className='inner'>
        <Block1 content={content.imageText1} />
        <Block2 content={content.imageText2} />
        <Block3 content={content.image} />
      </div>
    </div>
  )
}

export default Component

const Block1 = ({ content }) => {
  const { image, title, body, link } = content
  return (
    <Inview className='block block-1 fade-in'>
      <div className='image'>
        <ImageWrap image={image} />
      </div>
      <div className='text'>
        <h4>{title}</h4>
        <div>{renderRichText(body)}</div>
        {link && <ButtonLink content={{ to: link }} />}
      </div>
    </Inview>
  )
}

const Block2 = ({ content }) => {
  const { image, title, body, link } = content
  return (
    <Inview className='block block-2 fade-in'>
      <div className='image'>
        <ImageWrap image={image} />
        <div className='swoosh-wrap'>
          <Swoosh left='-30%' width='200%' opacity='0.1' />
        </div>
      </div>
      <div className='text'>
        <h4>{title}</h4>
        <div>{renderRichText(body)}</div>
        {link && <ButtonLink content={{ to: link }} />}
      </div>
      <div className='swoosh-wrap-mobile'>
        <Swoosh left='-30%' width='200%' opacity='0.1' />
      </div>
    </Inview>
  )
}

const Block3 = ({ content }) => {
  const { image, caption } = content
  return (
    <Inview className='block block-3 fade-in'>
      <div className='image'>
        <ImageWrap image={image} />
        {caption && <h4 className='caption'>{caption}</h4>}
      </div>
    </Inview>
  )
}
