import React from 'react'
import ContactUpper from 'components/ContactUpper'
import ContactLower from 'components/ContactLower'

const Component = ({ content }) => {
  return (
    <div className='component-contact-block'>
      <ContactUpper content={content} />
      <ContactLower content={content} />
    </div>
  )
}

export default Component
