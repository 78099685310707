import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Swoosh from 'components/Swoosh'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  const { image, caption, title, body } = content
  return (
    <Inview className='component-image-text-block'>
      <div className='inner fade-in'>
        <div className='image'>
          <ImageWrap image={image} />
          <div className='swoosh-wrap standard'>
            <Swoosh right='0' width='200%' opacity='0.1' />
          </div>
          <div className='swoosh-wrap reversed'>
            <Swoosh left='0' width='200%' opacity='0.1' />
          </div>
          {caption && <h4 className='caption'>{caption}</h4>}
        </div>
        <div className='text'>
          <h4>{title}</h4>
          <div className='body'>{renderRichText(body)}</div>
        </div>
      </div>
    </Inview>
  )
}

export default Component
